<script setup lang="ts">
import { register } from 'swiper/element/bundle';
import type { CmsBlock } from '@shopware-pwa/types';

register();

const slider = ref(null);

// const teasers = props.content.slots;
const props = defineProps<{ content: CmsBlock; title: string }>();

const teasers = computed(() => {
  const blockValue = props.content.slots[0].translated.config.content.value;
  try {
    const parsedValue = JSON.parse(blockValue);
    return parsedValue;
  } catch (e) {
    console.error('Error parsing the value', e);
  }
  return blockValue;
});
</script>
<template>
  <div class="lg:px-15 px-5 py-10">
    <div class="flex flex-col gap-2">
      <div class="flex justify-between">
        <h2>{{ title }}</h2>
        <div class="flex gap-2">
          <button
            class="bg-transparent"
            aria-label="previous"
            @click="() => slider.swiper.slidePrev()"
          >
            <img v-lazy-load 
              data-src="/icons/shared/arrow-down-active.svg"
              class="h-6 w-6 rotate-90"
              alt="previous"
            />
          </button>
          <button
            class="bg-transparent"
            aria-label="next"
            @click="() => slider.swiper.slideNext()"
          >
            <img v-lazy-load 
              data-src="/icons/shared/arrow-down-active.svg"
              class="rotate-270 h-6 w-6"
              alt="next"
            />
          </button>
        </div>
      </div>
    </div>
    <ClientOnly>
      <swiper-container
        ref="slider"
        :loop="false"
        :slides-per-view="1.25"
        :slides-per-group="1"
        :center-insufficient-slides="true"
        space-between="10"
        :centered-slides="false"
        :breakpoints="{
          1024: {
            slidesPerView: 3.25,
          },
        }"
      >
        <swiper-slide v-for="teaser in teasers" :key="teaser.id">
          <RouterLink :to="teaser.url" class="flex flex-col">
            <NuxtImg
              class="max-w-[560px]"
              :src="teaser.media.url"
              :alt="teaser.name"
            />
            <p
              class="bg-dark absolute bottom-0 block w-full max-w-[560px] px-6 py-3 text-white"
            >
              {{ teaser.name }}
            </p>
          </RouterLink>
        </swiper-slide>
      </swiper-container>
    </ClientOnly>
  </div>
</template>
